@import '~styles/mixins';

.cardStakeBlock {
  z-index: 1;
  display: grid;
}

.cardHeading {
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-auto-flow: column;
  column-gap: 16px;
  align-items: center;
  justify-items: start;
}

.btnPresent {
  justify-self: end;
  color: $gray-300;
  transition: 0.25s color;

  &:hover {
    color: $white;
  }
}

.cardHeadingContainer {
  display: grid;
  grid-auto-flow: column;
  justify-content: space-between;
}

.hrHeading {
  margin: 24px;

  @include b-d(lg) {
    margin: 16px 0;
  }
}

.inputWrapper {
  position: relative;
}

.textBalance {
  position: absolute;
  right: 0;
  color: $gray-500;
}

.gridPercentButtons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: column;
  column-gap: 8px;
  margin-top: 8px;
}

.textResult {
  margin-top: 24px;
}

.inputRange {
  margin-top: 8px;
}

.btnStake {
  margin-top: 24px;
}

.table {
  margin-top: 16px;

  td {
    &:first-child {
      color: $gray-500;
    }

    &:last-child {
      font-weight: $font-weight-semi-bold;
      text-align: end;

      > span {
        color: transparent;
        background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
        background-clip: text;
      }
    }
  }

  tr:not(:last-child) td {
    padding-bottom: 16px;
  }
}

.cardStats {
  position: relative;
  display: grid;
  row-gap: 24px;
  background: radial-gradient(
      98.54% 76.07% at 93.79% 17.7%,
      rgb(241 150 209 / 10%) 0%,
      rgb(207 151 236 / 10%) 23.75%,
      rgb(153 189 237 / 10%) 47.71%,
      rgb(156 236 202 / 10%) 66.98%,
      rgb(255 221 180 / 10%) 82.6%,
      rgb(248 150 197 / 10%) 100%
    ),
    $body-bg;
  border-width: 0;

  &::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    z-index: -1;
    content: '';
    background: radial-gradient(
      98.54% 76.07% at 93.79% 17.7%,
      #f196d1 0%,
      #cf97ec 23.75%,
      #99bded 47.71%,
      #9cecca 66.98%,
      #ffddb4 82.6%,
      #f896c5 100%
    );
    border-radius: 25px;
  }
}

.statsGrid {
  @include text-syne-16-semi-bold;

  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, 1fr);
  row-gap: 24px;
  font-weight: $font-weight-base;

  > div {
    display: grid;
    row-gap: 8px;
  }

  h5 {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    color: $gray-500;
  }

  b {
    @include text-syne-18-bold;
  }

  @include b-d(md) {
    @include text-syne-14;

    b {
      @include text-syne-14-bold;
    }
  }
}

.btnWrapper {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 10px;

  @include b-d(md) {
    grid-auto-flow: unset;
  }
}

.modalBody {
  position: relative;
  z-index: 1;
  display: grid;
  row-gap: 12px;
}

.date {
  padding-top: 15px;
  font-size: 1.2rem;
  color: lightblue;
}
