@import '~styles/mixins';

.blockNftStaking {
  display: grid;
  row-gap: 24px;

  @include b-d(md) {
    row-gap: 16px;
  }
}

.gridTop {
  display: grid;
  gap: 16px;

  @include b-up(md) {
    grid-auto-flow: column;
  }
}

.becomeFarmerBtn {
  width: 300px;
  margin: auto;
}

.textHeading {
  margin-top: 20px;
  color: transparent;
  text-align: center;
  background: linear-gradient(
    104.51deg,
    #333 -5.26%,
    #fff 49.16%,
    #2b2b2b 106.37%
  );

  // noinspection CssInvalidPropertyValue
  background-clip: text;
}
