/* stylelint-disable scss/operator-no-newline-after */
@import '~styles/mixins';

.landingPage {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 320px 1fr;
  grid-auto-flow: column;
  gap: 32px;
  padding-top: 80px;

  @include b-d(lg) {
    grid-template-rows: none;
    grid-template-columns: auto;
    grid-auto-flow: row;
    padding-top: 60px;
  }

  @include b-d(md) {
    row-gap: 24px;
  }

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -1;
    display: block;
    height: 100%;
    content: '';
    background: no-repeat url('./assets/bg-center.svg') left -800px center / 1924px
        459px,
      no-repeat url('./assets/bg-l.svg') top 170px left 0 / 270px 590px,
      no-repeat url('./assets/bg-r.svg') top 100px right 0 / 338px 760px;

    @include b-btw(md, lg) {
      background: no-repeat url('./assets/bg-center.svg') left 55% top 80px /
        1924px 459px;
    }

    @include b-d(md) {
      background: no-repeat url('./assets/bg-center.svg') left 55% top 30px /
        1924px 459px;
    }
  }

  &::after {
    @include font-family-joyride;

    position: absolute;
    top: 140px;
    left: 50%;
    z-index: -1;
    display: block;
    font-size: 124px;
    line-height: 1;
    color: transparent;
    letter-spacing: -0.01em;
    white-space: nowrap;
    content: 'ASSET HUB';
    transform: translateX(-50%);
    -webkit-text-stroke: 1px rgba($white, 12%);

    @include b-btw(xl, xxl) {
      font-size: 9.5vw;
    }

    @include b-btw(lg, xl) {
      font-size: 10.5vw;
    }

    @include b-btw(md, lg) {
      font-size: 64px;
    }

    @include b-d(md) {
      font-size: 40px;
    }
  }
}

.gridVideo {
  display: grid;
  grid-template-rows: 148px 1fr;
  grid-template-columns: 320px auto;
  grid-auto-flow: column;
  row-gap: 16px;
  column-gap: 32px;

  @include b-up(lg) {
    grid-column: 1 / 3;
  }

  @include b-d(lg) {
    grid-template-rows: none;
    grid-template-columns: auto;
    grid-auto-flow: row;
    row-gap: 32px;
  }

  @include b-d(md) {
    row-gap: 16px;
  }
}

.gridText {
  display: grid;
  row-gap: 8px;
  align-self: end;

  @include b-d(lg) {
    text-align: center;
  }

  p {
    @include b-up(md) {
      line-height: 26px;
    }

    @include b-d(md) {
      @include text-syne-14;
    }
  }
}

.nav {
  align-self: start;
}

.cardVideo {
  align-self: center;
  box-shadow: 0 0 18px 0 #36b3c0b2;

  @include b-up(lg) {
    grid-row: 1 / 3;
  }

  @include b-d(lg) {
    grid-row: 2 / 3;
  }
}

.banner {
  @include b-up(lg) {
    grid-column: 2 / 3;
  }
}
