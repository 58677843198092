@import '~styles/mixins';

.blockNftStaked {
  display: grid;
  row-gap: 16px;
}

.gridNfts {
  margin-top: 16px;

  @include b-d(lg) {
    margin-top: 8px;
  }
}

.btnWrapper {
  padding: 20px 20px 20px 12px;

  @include b-d(ipad) {
    justify-self: center;
    padding: 15px;
  }
}

.btnStake {
  margin: 10px 0;
}

.textHeading {
  margin-top: 20px;
  color: transparent;
  text-align: center;
  background: linear-gradient(
    104.51deg,
    #333 -5.26%,
    #fff 49.16%,
    #2b2b2b 106.37%
  );

  // noinspection CssInvalidPropertyValue
  background-clip: text;
}

.buttons {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  grid-gap: 20px;

  @include b-d(sm) {
    grid-auto-columns: unset;
    grid-auto-flow: row;
    grid-gap: unset;
  }
}

.modalBody {
  position: relative;
  z-index: 1;
  display: grid;
  row-gap: 12px;
}

.btnClaim {
  display: grid;
  grid-template-columns: 24px auto;
  column-gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px 16px;

  @include b-btw(md, lg) {
    min-width: 324px;
  }

  @include b-d(md) {
    padding: 12px 9px;
  }
}

.iconWrap {
  display: grid;
  place-content: center;
  width: 24px;
  height: 24px;
  background: rgba(247, 160, 6, 20%);
  border-radius: 50%;
}
