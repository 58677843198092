@import '~styles/mixins';

.navTransparent {
  display: grid;
  row-gap: 8px;
  padding: 24px;
  margin: 0;
  list-style-type: none;
  background: linear-gradient(
    227.18deg,
    rgba(14, 14, 14, 19.1%) -9.41%,
    rgba(14, 14, 14, 66%) 97.44%
  );
  border: 1px solid $gray-800;
  border-radius: 24px;
  backdrop-filter: blur(44px);

  @include b-d(lg) {
    background: $gray-900;
  }

  @include b-d(md) {
    padding: 16px;
  }

  a {
    @include text-syne-14-bold;

    display: block;
    padding: 16px;
    border-radius: 12px;
    transition: all 0.25s;

    &:hover {
      color: currentColor;
      background: rgba(14, 14, 14, 80%);
    }

    @include b-btw(mg, lg) {
      text-align: center;
    }

    @include b-d(lg) {
      &:hover {
        background: $gray-850;
      }
    }

    &.disabled {
      color: $gray-600;
      pointer-events: none;
    }
  }
}
