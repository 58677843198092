@import '~styles/mixins';

.bannerFeatures {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-flow: column;
  column-gap: 16px;
  padding: 24px;
  background: linear-gradient(
    138.66deg,
    rgba(30, 30, 30, 60%) 0%,
    rgba(0, 0, 0, 0%) 76.6%
  );
  border-radius: 24px;

  @include b-d(lg) {
    grid-template-columns: auto;
    grid-auto-flow: row;
    row-gap: 16px;
    padding: 16px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 2px;
    pointer-events: none;
    content: '';
    background: linear-gradient(180deg, #2e2e2e 0, rgba(9, 9, 9, 0%) 100%);
    border-radius: 24px;
    mask: linear-gradient(#fff 0, #fff 100%) content-box,
      linear-gradient(#fff 0, #fff 100%) border-box;
    mask-composite: exclude;
  }
}

.featureItem {
  display: grid;
  grid-auto-flow: column;
  row-gap: 4px;
  column-gap: 8px;
  align-items: center;
  justify-content: start;
}

.img {
  @include b-d(lg) {
    width: 48px;
    height: 48px;
  }

  &.spanRows {
    grid-row: 1 / 3;
  }
}

.textMain {
  &.extraMargin {
    @include b-up(lg) {
      margin-top: 24px;
    }
  }
}

.textExtra {
  font-size: 12px;
  font-weight: $font-weight-semi-bold;
  line-height: 16px;
  color: $gray-700;
}
