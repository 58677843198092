@import '~styles/mixins';

.videoWrapperOuter {
  padding: 2px;
  background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
  border-radius: 26px;

  @include b-d(md) {
    border-radius: 22px;
  }
}

.videoWrapperInner {
  @include fix-ios-border-radius;

  position: relative;
  padding-top: calc(9 / 16 * 100%);
  overflow: hidden;
  background-color: $gray-900;
  border-radius: 24px;

  @include b-d(md) {
    border-radius: 20px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    padding: 2px;
    pointer-events: none;
    content: '';
    user-select: none;
    background: linear-gradient(
      100.22deg,
      rgba(42, 189, 232, 25%) 3.4%,
      rgba(42, 189, 232, 0%) 45.23%,
      rgba(42, 189, 232, 0%) 47.28%,
      rgba(32, 207, 178, 24%) 108.14%
    );
    border-radius: 24px;
    opacity: 0;
    transition: all 0.25s;

    @include b-d(md) {
      border-radius: 20px;
    }
  }

  &.glowVisible {
    &::after {
      opacity: 1;
    }
  }
}

.playerRoot {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;

  &:hover {
    /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
    :global(.react-player-play-icon) {
      transform: scale(1.12);
    }
  }
}
