@import '~styles/mixins';

.banner {
  position: relative;
  display: grid;
  row-gap: 16px;
  width: 100%;
  padding-bottom: 25px;

  @include b-d(lg) {
    padding: 16px;
    background: #121212;
    border: 2px solid $gray-800;
    border-radius: 24px;
  }
}

.bannerInner {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 10px;
  column-gap: 12px;
  align-content: start;
  width: 100%;
  overflow: hidden;
  font-weight: 300;
  color: $white;
  text-align: start;
  border-bottom: 0;
  border-radius: 23px;
  transform: translateZ(0);

  &.staked {
    grid-auto-flow: row;
  }

  @include b-up(lg) {
    height: 188px;
    padding: 8px 10px 10px;
    filter: drop-shadow(0 2px $gray-800) drop-shadow(2px 0 $gray-800)
      drop-shadow(-2px 0 $gray-800) drop-shadow(0 -2px $gray-800);
  }

  @include b-d(lg) {
    row-gap: 16px;
    padding: 2px;
  }

  @include b-btw(md, lg) {
    grid-template-rows: auto 115px;
    column-gap: 20px;
  }

  @include b-d(md) {
    grid-template-rows: auto repeat(2, 143px);
    grid-template-columns: auto;
  }

  &::before {
    position: absolute;
    top: 0;
    z-index: -2;
    width: 100%;
    height: 100%;
    content: '';
    background: #121212;
    mask-image: url("data:image/svg+xml,%3Csvg width='1599' height='191' viewBox='0 0 1599 191' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.75 0.75H1598.25V190.25H1006.5C1002.53 190.25 999.269 186.967 998.354 182.898C994.664 166.5 980.013 154.25 962.5 154.25H634.5C616.987 154.25 602.336 166.5 598.646 182.898C597.731 186.967 594.474 190.25 590.5 190.25H0.75V0.75Z' fill='%23000000'/%3E%3C/svg%3E%0A");
    mask-position: center;
    mask-repeat: no-repeat;

    @include b-d(lg) {
      display: none;
    }
  }
}

.textTopStaking {
  @include b-up(xxl) {
    margin-left: 16px;
  }

  @include b-btw(lg, xxl) {
    padding-left: 8px;
    font-size: 13px;
    line-height: 20px;
  }

  @include b-d(lg) {
    text-align: center;
  }

  @include b-up(md) {
    grid-column: 1 / 3;
  }

  @include b-d(md) {
    font-size: 11px;
    line-height: 22px;
  }
}

.textTopStaked {
  text-align: center;

  @include b-up(md) {
    grid-column: 1 / 3;
  }
}

.blockInfo {
  position: relative;
  display: grid;
  column-gap: 12px;
  align-content: start;

  &::before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2;
    display: block;
    content: '';
    border-radius: 20px;
  }

  /* Variant: staked */
  &.staking {
    grid-template-rows: repeat(2, auto);
    grid-template-columns: 1fr auto;
    padding: 28px 22px 0;

    @include b-btw(md, lg) {
      @include text-joyride-20;

      padding: 38px 14px;
    }

    @include b-d(md) {
      padding: 28px 14px;
    }
  }

  /* Variant: staked */
  &.staked {
    padding: 22px;

    @include b-btw(md, lg) {
      padding: 24px 22px;
    }

    @include b-d(md) {
      padding: 43px 22px;
    }
  }

  /* Variant: landing */
  &.landing {
    row-gap: 8px;
    padding: 18px 24px 18px 38px;

    @include b-btw(md, lg) {
      padding: 10px 14px;
    }

    @include b-d(md) {
      padding: 38px 14px;
      font-size: 18px;
    }
  }
}

.blockYellow {
  filter: drop-shadow(0 2px #fcfa48) drop-shadow(2px 0 #fcfa48)
    drop-shadow(-2px 0 #fcfa48) drop-shadow(0 -2px #fcfa48);

  &::before {
    @include b-up(lg) {
      mask-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 633 140'%3E%3Cpath d='M.5 140h420.86c3.8 0 7.01-2.71 8.3-6.3A45.02 45.02 0 0 1 472 104h145a16 16 0 0 0 16-16V0H.5v140Z' fill='%23000'/%3E%3C/svg%3E");
      mask-position: right 0 bottom 0;
      mask-repeat: no-repeat;
      mask-size: auto 100%;
      background: no-repeat url('assets/glow-yellow-1.svg') left -80px top -80px /
          400px 235px,
        no-repeat url('assets/glow-yellow-2.svg') right -80px center / 227px 268px,
        no-repeat url('assets/bg-cards-1.png') left -8px bottom -40px / 170px
          130px,
        no-repeat url('assets/bg-cards-2.png') left 64% bottom -12px / 204px 127px,
        no-repeat url('assets/bg-cards-3.png') right -48px top -30px / 168px
          95px,
        url('assets/bg-legends.png') left 0 top 40% / 549px 319px, #121212;
    }

    @include b-btw(md, lg) {
      background: no-repeat url('assets/glow-yellow-1.svg') left -90px top -100px /
          400px 235px,
        no-repeat url('assets/glow-yellow-2.svg') right -90px center / 227px 268px,
        no-repeat url('assets/bg-cards-1.png') left 0 bottom -16px / 85px 65px,
        no-repeat url('assets/bg-cards-2.png') right 4px bottom -14px / 102px 64px,
        no-repeat url('assets/bg-cards-3.png') right -56px top -30px / 168px
          95px,
        url('assets/bg-legends.png') left 0 top 40% / 549px 319px, #121212;
    }

    @include b-d(md) {
      background: no-repeat url('assets/glow-yellow-1.svg') left -80px top -80px /
          400px 235px,
        no-repeat url('assets/glow-yellow-2.svg') right -80px center / 227px 268px,
        no-repeat url('assets/bg-cards-1.png') left -4px bottom -40px / 170px
          130px,
        no-repeat url('assets/bg-cards-2.png') right -40px bottom -32px / 204px
          127px,
        no-repeat url('assets/bg-cards-3.png') right 50% top -44px / 168px 95px,
        url('assets/bg-legends.png') left 0 top 40% / 549px 319px, #121212;
    }
  }
}

.textYellow {
  grid-column: 2 / 3;
}

.blockGreen {
  filter: drop-shadow(0 2px #1fcfb1) drop-shadow(2px 0 #1fcfb1)
    drop-shadow(-2px 0 #1fcfb1) drop-shadow(0 -2px #1fcfb1);

  &::before {
    @include b-up(lg) {
      mask-image: url("data:image/svg+xml,%3Csvg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 633 140'%3E%3Cpath d='M632.5 140H211.64c-3.8 0-7.01-2.71-8.3-6.3A45.02 45.02 0 0 0 161 104H16A16 16 0 0 1 0 88V0h632.5v140Z' fill='%23000'/%3E%3C/svg%3E");
      mask-position: left 0 bottom 0;
      mask-repeat: no-repeat;
      mask-size: auto 100%;
      background: no-repeat url('assets/glow-green-1.svg') left -90px top -80px /
          227px 268px,
        no-repeat url('assets/glow-green-2.svg') right -85px top -80px / 400px
          235px,
        no-repeat url('assets/bg-blocks-1.png') left -16px bottom 18px / 64px 65px,
        no-repeat url('assets/bg-blocks-2.png') right -2px bottom -4px / 121px
          106px,
        url('assets/bg-logos.png') left 0 top 40% / 557px 260px, #121212;
    }

    @include b-d(lg) {
      background: no-repeat url('assets/glow-green-1.svg') left -84px top -75px /
          227px 268px,
        no-repeat url('assets/glow-green-2.svg') right -80px top -75px / 400px
          235px,
        no-repeat url('assets/bg-blocks-1.png') left -2px bottom -30px / 64px
          65px,
        no-repeat url('assets/bg-blocks-2.png') right -26px bottom -16px / 121px
          106px,
        url('assets/bg-logos.png') left 0 top 40% / 557px 260px, #121212;
    }
  }
}

.textGray {
  margin-top: -2px;
  font-family: $font-family-base;
  font-size: 12px;
  font-weight: $font-weight-semi-bold;
  color: transparent;
  text-shadow: 0 0 4px rgba(#000, 0.4);
  background: linear-gradient(
    142.74deg,
    #fff 0%,
    rgba(255, 255, 255, 0%) 114.2%
  );
  background-clip: text;
}

.button {
  @include b-up(lg) {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    width: 100%;
    max-width: 374px;
    transform: translateX(-50%);
  }

  @include b-d(md) {
    padding: 17px 12px;
  }
}

.landingValuesGrid {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-auto-flow: column;
  row-gap: 2px;
  filter: drop-shadow(0 0 2px rgba(#000, 20%));

  @include b-d(lg) {
    row-gap: 0;
  }
}

.landingTextGray {
  line-height: 1;
  opacity: 0.6;
}
