// noinspection CssUnknownTarget
@import '~styles/mixins';

.cardStakeCalculator {
  display: grid;
  row-gap: 16px;
}

.cardHeading {
  display: grid;
  grid-auto-flow: column;
  column-gap: 16px;
  align-items: center;
  justify-content: start;

  @include b-btw(lg, xl) {
    column-gap: 8px;
  }
}

.hrHeading {
  @include b-up(lg) {
    margin: 8px 0;
  }
}

.blockPlusSign {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  width: 48px;
  height: 36px;
  color: $white;
  background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
  border-radius: 34px;
}

.blockSeparator {
  position: relative;
  display: grid;

  @include b-btw(md, lg) {
    margin-top: 29px;
  }

  .hr {
    position: absolute;
    top: 50%;
    left: 0;

    @include b-btw(md, lg) {
      display: none;
    }
  }
}

.blockInputs {
  display: grid;
  row-gap: 16px;

  @include b-btw(md, lg) {
    grid-auto-flow: column;
    column-gap: 12px;
    align-content: center;
  }
}

.textLabel {
  margin-top: 16px;
  color: $gray-500;
  text-align: center;
}

.textResult {
  @include font-family-joyride;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: -8px -16px 16px 0;
  font-size: 28px;
  line-height: 1.25;
  text-align: center;
  letter-spacing: -0.01em;

  > div {
    margin-top: 8px;
    margin-left: 16px;
  }

  @include b-btw(md, lg) {
    margin-bottom: 8px;
  }

  @include b-d(md) {
    font-size: 20px;
  }

  &.fontSmaller {
    @include b-btw(md, xxl) {
      font-size: 20px;
    }
  }
}

.textBlock {
  display: grid;
  grid-auto-flow: column;
  column-gap: 4px;
  align-items: center;
}
