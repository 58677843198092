@import '~styles/mixins';

.headerWrapper {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: $z-index-header;
  background-color: $black;
}

.header {
  display: grid;
  grid-auto-flow: column;
  column-gap: 32px;
  align-items: center;
  justify-content: space-between;
  height: 155px;
  background-color: $black;

  @include b-d(xl) {
    height: 103px;
  }

  @include b-btw(lg, xxl) {
    column-gap: 4px;
  }

  .navMenu {
    @include b-d(xl) {
      display: none;
    }
  }

  .gridHeaderRight {
    display: grid;
    grid-auto-flow: column;
    column-gap: 16px;
    align-items: center;
  }

  .btnWrapper {
    position: relative;
    z-index: $z-index-header + 10;
  }

  .logo {
    width: 276px;
  }

  .btnMenu {
    @include b-up(md) {
      display: none;
    }
  }

  .btnWallet {
    display: flex;
    align-items: center;
  }

  .btnConnectWallet {
    @include b-up(xl) {
      padding: 12px 22.5px;
    }
  }
}
