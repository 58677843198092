.container {
  /* stylelint-disable-next-line */
  @extend .container-fluid;

  min-width: 0;
  max-width: 1368px;

  &-fluid {
    width: 100%;
    margin: auto;

    @include b-up(lg) {
      padding: 0 $container-padding-x-xxl;
    }

    @include b-btw(md, lg) {
      padding: 0 $container-padding-x-md;
    }

    @include b-d(md) {
      padding: 0 $container-padding-x-xs;
    }
  }
}
