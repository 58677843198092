// noinspection CssUnknownTarget
@import '~styles/mixins';

.cardTokenInfo {
  @include fix-ios-border-radius;

  display: grid;
  grid-auto-flow: column;
  align-items: center;
  overflow: hidden;
  border-radius: 24px;

  @include b-d(ipad) {
    grid-auto-flow: row;
  }

  &::after {
    position: absolute;
    top: 16px;
    right: 16px;
    bottom: 16px;
    left: 24px;
    z-index: -1;
    content: '';
    background: linear-gradient(
        transparent 5%,
        rgb(154 59 204 / 30%) 5%,
        rgb(154 59 204 / 30%) 100%
      ),
      linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
    filter: blur(16px);
    border-radius: 91px;
    transform: translateZ(0%);

    @include b-d(lg) {
      top: 20px;
      right: 12px;
      bottom: 20px;
      left: 12px;
    }

    @include b-d(md) {
      top: 8px;
      right: 11px;
      bottom: 8px;
      left: 11px;
    }
  }
}

.baseLayer {
  display: grid;
  grid-auto-flow: column;
  gap: 16px;
  min-height: 100%;
  padding: 22px 24px;
  background-color: rgb(24 24 24 / 72%);
  border-radius: 24px;
  transform: translateZ(0);

  @include b-d(lg) {
    padding: 24px;
  }

  @include b-d(md) {
    padding: 16px;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    padding: 2px;
    content: '';
    background: linear-gradient(170deg, #333 0%, #181818 100%);
    border-radius: 26px;
    mask: linear-gradient(#fff 0, #fff 100%) content-box,
      linear-gradient(#fff 0, #fff 100%) border-box;
    mask-composite: exclude;
  }

  > div:nth-child(3) {
    @include b-d(md) {
      grid-row: 2 / 3;
      grid-column: 1 / 3;
    }
  }
}
