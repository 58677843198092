// noinspection CssUnknownTarget
@import '~styles/mixins';

.layout {
  display: flex;
  flex-direction: column;
  min-width: 375px;
  min-height: 100vh;
}

.main {
  flex-grow: 1;
  padding-top: 155px;

  @include b-d(xl) {
    padding-top: 103px;
  }
}

%blendShadow {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  display: block;
  width: $container-padding-x-xs;
  content: '';

  @include b-up(md) {
    display: none;
  }
}
