@import '~styles/mixins';

.blockassetLogo {
  display: grid;
  grid-template-columns: 55px 168px;
  column-gap: 7px;
  align-items: center;

  &:hover {
    color: currentColor;
  }

  // &.header {
  //   @include b-d(md) {
  //     grid-template-columns: 38px;

  //     .textBlockasset {
  //       display: none;
  //     }
  //   }
  // }

  &.footer {
    grid-template-columns: 36px 108px;
    column-gap: 6px;
    margin-left: -1px;
  }
}
