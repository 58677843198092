@import '~styles/mixins';

.cardInfo {
  position: relative;
  display: grid;
  row-gap: 16px;
  padding: 16px;
  background: rgba(24, 24, 24, 72%);
  border-radius: 24px;

  @include b-btw(md, lg) {
    align-items: center;
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    padding: 2px;
    content: '';
    background: linear-gradient(170deg, #333 0%, #181818 100%);
    border-radius: 24px;
    mask: linear-gradient(#fff 0, #fff 100%) content-box,
      linear-gradient(#fff 0, #fff 100%) border-box;
    mask-composite: exclude;
  }

  /* Variant: staked */
  &.staked {
    @include b-btw(md, lg) {
      grid-template-rows: repeat(2, auto);
      grid-template-columns: repeat(2, auto);
      padding: 24px;
    }
  }
}

.gridDataItem {
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-template-columns: repeat(2, auto);
  row-gap: 4px;
  column-gap: 16px;
  justify-content: start;

  h4 {
    align-self: end;
  }

  p {
    align-self: start;
    color: $gray-300;

    @include b-d(md) {
      @include text-joyride-20;
    }
  }

  /* Variant: staked */
  &.staked {
    @include b-btw(md, lg) {
      &:nth-child(3) {
        grid-column: 1 / 3;
      }

      p {
        @include text-joyride-20;
      }
    }
  }
}

.iconWrapper {
  display: flex;
  grid-row: 1 / 3;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: linear-gradient(
    327.51deg,
    rgba(0, 112, 254, 20%) -12.94%,
    rgba(0, 254, 150, 20%) 88.07%
  );
  border-radius: 100%;

  @include b-d(md) {
    width: 52px;
    height: 52px;
  }

  svg {
    fill: url('#item-gradient-primary-inverted');

    @include b-d(md) {
      width: 24px;
      height: 24px;
    }
  }

  /* Variant: staked */
  &.staked {
    @include b-d(lg) {
      width: 52px;
      height: 52px;

      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}

.textUnit {
  font-size: 80%;
}
