@import '~styles/mixins';

.blockMain {
  display: grid;
  row-gap: 24px;

  > div:not(.cardInfo) {
    width: 100%;
    max-width: 604px;
    margin: 0 auto;
  }

  .stakeCalculator {
    @include b-d(lg) {
      margin-top: -8px;
    }
  }
}
