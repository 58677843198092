@import '~styles/mixins';

.pageWrapper {
  padding-top: 24px;
}

.assetHub {
  display: grid;
  grid-template-columns: 260px 1fr;
  column-gap: 24px;
  align-items: start;
  width: 100%;
  color: white;

  @include b-d(lg) {
    grid-template-columns: auto;
    row-gap: 24px;
  }

  @include b-d(md) {
    row-gap: 16px;
  }
}

aside {
  display: grid;
  row-gap: 24px;
}

.passwordInput {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
}
