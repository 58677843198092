@import '~styles/mixins';

.cardNft {
  position: relative;
  display: grid;
  grid-template-rows: repeat(2, auto) 1fr;
  row-gap: 8px;
  padding: 8px;
  color: $white;
  background-color: $gray-900;
  border: 1px solid $gray-800;
  border-radius: 24px;
}

.imgAspectRatioContainer {
  @include fix-ios-border-radius;

  position: relative;
  align-self: start;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  border-radius: 20px;
}

.imgOverlay {
  position: absolute;
  top: -1px;
  right: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 36%;
  padding: 8px;
  background: linear-gradient(
    180deg,
    rgb(13 13 13 / 90%) 0%,
    rgb(13 13 13 / 0%) 100%
  );
}

.imgOwner {
  @include fix-ios-border-radius;

  overflow: hidden;
  border-radius: 50%;
}

.imgNft {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.table {
  justify-self: stretch;
  width: 100%;
  margin: 2px 0;

  td {
    padding: 6px 0;

    &:last-child {
      @include text-syne-14-bold;

      padding-left: 8px;
      text-align: end;
    }
  }
}

.textGray {
  color: $gray-500;
}

.btnClaim {
  height: 48px;
  padding: 0 16px;
  box-shadow: none;
}

.cardUnstake {
  margin-top: auto;
}

.gridAthlete {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  column-gap: 10px;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.textAthleteName {
  color: transparent;
  background: linear-gradient(180deg, #8d8d8d 0%, #fff 100%);
  background-clip: text;
}

.imgAthlete {
  @include fix-ios-border-radius;

  overflow: hidden;
  border-radius: 100%;
}
