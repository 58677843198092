.dropdown {
  $this: &;

  &-menu {
    &-dark {
      row-gap: 4px;
      width: 100%;
      min-width: 0;
      padding: 0 0 12px;
      border-top-width: 0;
      border-radius: 0 0 24px 24px;

      &.show {
        display: grid;
      }

      #{$this}-item {
        display: grid;
        grid-auto-flow: column;
        column-gap: 8px;
        align-items: center;
        justify-content: start;
        font-weight: inherit;
        transition: color 0.25s;

        &:hover {
          color: $gray-300;
          background-color: transparent;
        }

        &:focus {
          background: none;
        }

        &.is-copied {
          color: transparent;
          background: linear-gradient(180deg, #4b98cd 0%, #1fcfb1 100%);
          background-clip: text;

          svg {
            fill: url('#gradSuccess');
          }
        }
      }
    }

    &-dark-header-nav {
      row-gap: 4px;

      &.show {
        display: grid;
      }
    }

    &-dark-header-mobile-nav {
      row-gap: 16px;
      width: calc(100vw - #{$container-padding-x-xs} * 2);
      // iPhone 12 Pro Max width
      max-width: calc(428px - #{$container-padding-x-xs} * 2);
      max-height: calc(100vh - 96px - 8px);

      @include b-d(lg) {
        @include hideScrollbar;

        overflow-y: auto;
      }

      @include b-d(md) {
        max-height: calc(100vh - 80px - 8px);
      }

      &.show {
        display: grid;
      }

      #{$this}-item {
        @include text-syne-18-bold;

        padding: 6px;
      }
    }

    &-rainbow {
      row-gap: 8px;
      width: 100%;
      max-width: auto;
      padding: 8px 0;
      border-width: 1px;
      border-radius: 12px;

      &.show {
        display: grid;
      }

      #{$this}-item {
        @include text-syne-14-bold;

        padding: 16px;
      }
    }
  }

  &-toggle {
    &-dark-header-nav {
      @include text-syne-14-bold;

      display: grid;
      grid-auto-flow: column;
      column-gap: 8px;
      align-items: center;
      padding: $nav-link-padding-y $nav-link-padding-x;
      color: $gray-400;
      cursor: pointer;
      transition: 0.25s all;

      &:hover {
        color: $white;
      }

      svg {
        color: $gray-600;
        transition: all 0.25s;
      }

      &[aria-expanded='true'] {
        color: $black;
        background: $white;
        border-radius: 100px;

        svg {
          color: inherit;
          transform: rotateX(180deg);
        }
      }
    }

    &-dark-header-mobile-nav {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      color: $gray-400;
      background: linear-gradient(159.57deg, #202024 13.57%, #131314 88.31%);
      border-radius: 100%;
      transition: all 0.25s;

      &:hover {
        color: $white;
      }
    }

    &-rainbow {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      color: $white;
      text-align: start;
      background: radial-gradient(
        98.54% 76.07% at 93.79% 17.7%,
        rgba(241, 150, 209, 11%) 0%,
        rgba(207, 151, 236, 11%) 23.75%,
        rgba(153, 189, 237, 11%) 47.71%,
        rgba(156, 236, 202, 11%) 66.98%,
        rgba(255, 221, 180, 11%) 82.6%,
        rgba(248, 150, 197, 11%) 100%
      );
      border-radius: 12px;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 2px;
        content: '';
        background: radial-gradient(
          98.54% 76.07% at 93.79% 17.7%,
          #f196d1 0%,
          #cf97ec 23.75%,
          #99bded 47.71%,
          #9cecca 66.98%,
          #ffddb4 82.6%,
          #f896c5 100%
        );
        border-radius: 12px;
        mask: linear-gradient(#fff 0, #fff 100%) content-box,
          linear-gradient(#fff 0, #fff 100%) border-box;
        mask-composite: exclude;
      }

      /* stylelint-disable-next-line no-descending-specificity */
      svg {
        color: $gray-300;
        transition: all 0.25s;
      }

      &[aria-expanded='true'] {
        svg {
          color: $white;
          transform: rotateX(180deg);
        }
      }
    }
  }
}
